import { requestIdleCallback } from '$lib/utils/request-idle-callback'
import { get, readable, writable, type Readable } from 'svelte/store'
import * as Providers from './providers'
import type { Context, State } from './types'
const store = writable<Context>({} as Context)
export const liveChatStore = readable(store)

interface Props extends Partial<Omit<Context, 'setState' | 'state'>> {
  idlePeriod?: number
  loadWhileIdle?: boolean
}

const connection =
  typeof window !== 'undefined'
    ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
      window.navigator && (window.navigator as any).connection
    : null

function getState(): Promise<Context> {
  return new Promise((yeah) => {
    yeah(get(store))
  })
}

async function requestLoad({ loadWhenIdle }: { loadWhenIdle: boolean } = { loadWhenIdle: true }) {
  const { idlePeriod } = await getState()

  // Don't load if idlePeriod is 0, null or undefined
  if (typeof window === 'undefined' || !loadWhenIdle || !idlePeriod) return

  // Don't load if 2g connection or save-data is enabled
  if (connection && (connection.saveData || /2g/.test(connection.effectiveType))) return

  if (isNaN(idlePeriod)) return

  // deadline.timeRemaining() has an upper limit of 50 milliseconds
  // We want to ensure the page has been idle for a significant period of time
  // Therefore we count consecutive maximum timeRemaining counts and load chat when we reach our threshold
  let elapsedIdlePeriod = 0
  let previousTimeRemaining = 0
  const scheduleLoadChat = (deadline: IdleDeadline) => {
    if (elapsedIdlePeriod > idlePeriod) return loadChat({ open: false })

    const timeRemaining = deadline.timeRemaining()
    // To ensure browser is idle, only accumalte elapsedIdlePeriod when
    // two consecutive maximum timeRemaining's have been observed
    if (previousTimeRemaining > 49 && timeRemaining > 49) elapsedIdlePeriod += timeRemaining

    previousTimeRemaining = timeRemaining
    requestIdleCallback(scheduleLoadChat)
  }

  if (requestIdleCallback) {
    requestIdleCallback(scheduleLoadChat)
  } else {
    setTimeout(() => loadChat({ open: false }), idlePeriod)
  }
}

const loadChat = async ({ open = true }: { open: boolean } = { open: true }) => {
  const { providerKey, provider, appID, locale, baseUrl, beforeInit, onReady, state } =
    await getState()

  if (!providerKey) {
    //eslint-disable-next-line no-console
    console.error('No api key given to svelte-live-chat-loader')
    return
  }

  if (!provider) {
    //eslint-disable-next-line no-console
    console.error('No provider given to svelte-live-chat-loader')
    return
  }
  const chatProvider = Providers[provider]

  chatProvider.load({
    provider,
    state,
    providerKey,
    setState,
    appID,
    locale,
    baseUrl,
    beforeInit,
    onReady,
  })

  if (open) {
    chatProvider.open()
    if (state !== 'complete') setState('open')
  }
}

function setState(newState: State) {
  store.update((state) => {
    state.state = newState
    return state
  })
}

function init({ provider, providerKey, idlePeriod, loadWhileIdle }: Props) {
  return () => {
    if (import.meta.env.DEV) return
    store.update((state) => ({ ...state, provider, providerKey, idlePeriod }))
    requestLoad({ loadWhenIdle: loadWhileIdle != false })
  }
}

const readableStore: Readable<Context> & {
  loadChat: typeof loadChat
  init: typeof init
} = {
  subscribe: store.subscribe,
  loadChat,
  init,
}

export default readableStore
