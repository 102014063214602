import type { Context } from '../types'
import waitForLoad from '../utils/waitForLoad'

const domain = 'https://widget.intercom.io'

declare type IntercomWindowProps =
  | Record<string, string | number | boolean | null>
  | {
      email?: string
      user_id?: string
      created_at?: number
      name?: string
      phone?: string
      last_request_at?: number
      unsubscribed_from_emails?: boolean
      utm_campaign?: string
      utm_content?: string
      utm_medium?: string
      utm_source?: string
      utm_term?: string
      avatar?: string
      user_hash?: string
    }

declare global {
  interface Window {
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    Intercom: any
    intercomSettings: Partial<IntercomWindowProps>
  }
}

/* eslint-disable */
const loadScript = (appId: string): boolean => {
  if (window.Intercom) return false
  ;(function () {
    var w = window
    var ic = w.Intercom
    if (typeof ic === 'function') {
      ic('reattach_activator')
      ic('update', window.intercomSettings)
    } else {
      var d = document
      var i: any = function () {
        i.c(arguments)
      }
      i.q = []
      i.c = function (args: any) {
        i.q.push(args)
      }
      w.Intercom = i
      const l = () => {
        var s = d.createElement('script')
        s.type = 'text/javascript'
        s.async = true
        s.src = `${domain}/widget/${appId}`
        var x = d.getElementsByTagName('script')[0]
        x.parentNode?.insertBefore(s, x)
      }
      l()
    }
  })()
  return true
}
/* eslint-enable */

const load = ({
  providerKey,
  setState,
  beforeInit = () => undefined,
  onReady = () => undefined,
}: Context): boolean => {
  const loaded = loadScript(providerKey)

  // Continue as long as userlike hasn’t already been initialised.
  if (loaded) {
    beforeInit()
    window.Intercom('boot', { app_id: providerKey })
    waitForLoad(
      () => window.Intercom.booted,
      // Allow intercom to complete loading before removing fake widget
      () =>
        setTimeout(() => {
          setState('complete')
          onReady()
        }, 2000),
    )
  }

  return loaded
}

const open = (): void => window.Intercom('show')

export default {
  domain,
  load,
  open,
}
